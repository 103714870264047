var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title": _vm.$t("cip.dc.zljkcj.title.name2"),
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave("save")
          },
          "head-save-back": function ($event) {
            return _vm.headSave("back")
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c(
        "div",
        { staticClass: "newZtFormBox" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticStyle: {
                "background-color": "white",
                "margin-top": "10px",
              },
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-collapse",
                {
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-collapse-item",
                    {
                      attrs: {
                        name: "1",
                        title: _vm.$t("cip.dc.QTemplate.field.field"),
                      },
                    },
                    [
                      _c("template", { slot: "title" }, [
                        _c(
                          "i",
                          {
                            staticClass: "avue-group__title avue-group__header",
                            staticStyle: {
                              color: "#999999 !important",
                              "font-style": "normal",
                              padding: "0 10px",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-document",
                              staticStyle: {
                                "font-size": "20px",
                                padding: "0 10px",
                              },
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "cip.qm.business.qm.batch.field.baseInfo"
                                  )
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.zljkcj.field.InterfaceCode"
                                    ),
                                    prop: "infCode",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "new-form-input",
                                    attrs: {
                                      autocomplete: "off",
                                      disabled: "",
                                    },
                                    model: {
                                      value: _vm.form.infCode,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "infCode", $$v)
                                      },
                                      expression: "form.infCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.zljkcj.field.InterfaceAddress"
                                    ),
                                    prop: "infUrl",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "new-form-input",
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "cip.dc.zljkcj.field.InterfaceAddress"
                                      ),
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.form.infUrl,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "infUrl", $$v)
                                      },
                                      expression: "form.infUrl",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.zljkcj.field.AccessMode"
                                    ),
                                    prop: "synType",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "new-form-input",
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "cip.dc.zljkcj.field.AccessMode"
                                        ),
                                      },
                                      model: {
                                        value: _vm.form.synType,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "synType", $$v)
                                        },
                                        expression: "form.synType",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          label: _vm.$t(
                                            "cip.dc.zljkcj.field.increment"
                                          ),
                                          value: 1,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.zljkcj.field.SourceSystem"
                                    ),
                                    prop: "sourceId",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "new-form-input",
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "cip.dc.zljkcj.field.SourceSystem"
                                        ),
                                      },
                                      model: {
                                        value: _vm.form.sourceId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "sourceId", $$v)
                                        },
                                        expression: "form.sourceId",
                                      },
                                    },
                                    _vm._l(_vm.idList, function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.zljkcj.field.Addsteptimestamp"
                                    ),
                                    prop: "stepTime",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticClass: "new-form-input",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "cip.dc.zljkcj.field.Addsteptimestamp"
                                      ),
                                      type: "datetime",
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                    },
                                    model: {
                                      value: _vm.form.stepTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "stepTime", $$v)
                                      },
                                      expression: "form.stepTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.zljkcj.field.Interfaceconfigurationinformation"
                                    ),
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "new-form-input",
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "cip.dc.zljkcj.field.Interfaceconfigurationinformation"
                                      ),
                                      autocomplete: "off",
                                      disabled: "",
                                    },
                                    model: {
                                      value: _vm.form.config,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "config", $$v)
                                      },
                                      expression: "form.config",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.zljkcj.field.Endtimefield"
                                    ),
                                    prop: "endTimeField",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "new-form-input",
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "cip.dc.zljkcj.field.Endtimefield"
                                      ),
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.form.endTimeField,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "endTimeField", $$v)
                                      },
                                      expression: "form.endTimeField",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.zljkcj.field.Updatetimefield"
                                    ),
                                    prop: "tsField",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "new-form-input",
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "cip.dc.zljkcj.field.Updatetimefield"
                                      ),
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.form.tsField,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "tsField", $$v)
                                      },
                                      expression: "form.tsField",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.zljkcj.field.StepLengthdays"
                                    ),
                                    prop: "stepSize",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "new-form-input",
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "cip.dc.zljkcj.field.StepLengthdays"
                                      ),
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.form.stepSize,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "stepSize", $$v)
                                      },
                                      expression: "form.stepSize",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.zljkcj.field.ReturnStatusCode"
                                    ),
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "new-form-input",
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "cip.dc.zljkcj.field.ReturnStatusCode"
                                      ),
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.form.resultCode,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "resultCode", $$v)
                                      },
                                      expression: "form.resultCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.zljkcj.field.IncrementalTimeType"
                                    ),
                                    prop: "tsFieldType",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "new-form-input",
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "cip.dc.zljkcj.field.IncrementalTimeType"
                                        ),
                                      },
                                      model: {
                                        value: _vm.form.tsFieldType,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "tsFieldType", $$v)
                                        },
                                        expression: "form.tsFieldType",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          label: _vm.$t(
                                            "cip.dc.zljkcj.field.Timetype"
                                          ),
                                          value: 1,
                                        },
                                      }),
                                      _c("el-option", {
                                        attrs: {
                                          label: _vm.$t(
                                            "cip.dc.zljkcj.field.Timestamptype"
                                          ),
                                          value: 2,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.zljkcj.field.InterfaceName"
                                    ),
                                    prop: "infName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "new-form-input",
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "cip.dc.zljkcj.field.InterfaceName"
                                      ),
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.form.infName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "infName", $$v)
                                      },
                                      expression: "form.infName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.zljkcj.field.RequestMethod"
                                    ),
                                    prop: "requestMethod",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "new-form-input",
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "cip.dc.zljkcj.field.RequestMethod"
                                        ),
                                      },
                                      model: {
                                        value: _vm.form.requestMethod,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "requestMethod",
                                            $$v
                                          )
                                        },
                                        expression: "form.requestMethod",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { value: 1, label: "get" },
                                      }),
                                      _c("el-option", {
                                        attrs: { value: 2, label: "post" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.Token.field.Tokensourceid"
                                    ),
                                    prop: "tokenId",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "new-form-input",
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "cip.dc.Token.field.Tokensourceid"
                                        ),
                                      },
                                      model: {
                                        value: _vm.form.tokenId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "tokenId", $$v)
                                        },
                                        expression: "form.tokenId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.tokenidList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.infCode,
                                            value: item.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.zljkcj.field.Maximumtimestamp"
                                    ),
                                    prop: "deltaTime",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticClass: "new-form-input",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "cip.dc.zljkcj.field.Maximumtimestamp"
                                      ),
                                      type: "datetime",
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                    },
                                    model: {
                                      value: _vm.form.deltaTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "deltaTime", $$v)
                                      },
                                      expression: "form.deltaTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.zljkcj.field.dataType"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "new-form-input",
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "cip.dc.zljkcj.field.dataType"
                                        ),
                                      },
                                      model: {
                                        value: _vm.form.dataType,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "dataType", $$v)
                                        },
                                        expression: "form.dataType",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { value: 1, label: "get" },
                                      }),
                                      _c("el-option", {
                                        attrs: { value: 2, label: "post" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.zljkcj.field.StartTimeField"
                                    ),
                                    prop: "startTimeField",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "new-form-input",
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "cip.dc.zljkcj.field.StartTimeField"
                                      ),
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.form.startTimeField,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "startTimeField",
                                          $$v
                                        )
                                      },
                                      expression: "form.startTimeField",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.zljkcj.field.PrimarykeyField"
                                    ),
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "new-form-input",
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "cip.dc.zljkcj.field.PrimarykeyField"
                                      ),
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.form.primaryField,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "primaryField", $$v)
                                      },
                                      expression: "form.primaryField",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.zljkcj.field.TokenField"
                                    ),
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "new-form-input",
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "cip.dc.zljkcj.field.TokenField"
                                      ),
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.form.tokenField,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "tokenField", $$v)
                                      },
                                      expression: "form.tokenField",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.zljkcj.field.ReturnDataItem"
                                    ),
                                    prop: "resultData",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "new-form-input",
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "cip.dc.zljkcj.field.ReturnDataItem"
                                      ),
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.form.resultData,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "resultData", $$v)
                                      },
                                      expression: "form.resultData",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.zljkcj.field.ReturnDescription"
                                    ),
                                    prop: "resultMsg",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "new-form-input",
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "cip.dc.zljkcj.field.ReturnDescription"
                                      ),
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.form.resultMsg,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "resultMsg", $$v)
                                      },
                                      expression: "form.resultMsg",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.zljkcj.field.TimeTypeFormat"
                                    ),
                                    prop: "formatType",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "new-form-input",
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "cip.dc.zljkcj.field.IncrementalTimeTypeFormat"
                                      ),
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.form.formatType,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "formatType", $$v)
                                      },
                                      expression: "form.formatType",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "el-collapse-item",
                    { attrs: { name: "2" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c(
                          "i",
                          {
                            staticClass: "avue-group__title avue-group__header",
                            staticStyle: {
                              color: "#999999 !important",
                              "font-style": "normal",
                              padding: "0 10px",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-document",
                              staticStyle: {
                                "font-size": "20px",
                                padding: "0 10px",
                              },
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("cip.dc.zljkcj.field.RequestHeader")
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                      _c("el-row", [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-bottom": "20px",
                              "padding-left": "30px",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { icon: "el-icon-circle-plus-outline" },
                                on: { click: _vm.headerListAddFn },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "cip.dc.zljkcj.field.AddRequestHeader"
                                    )
                                  ) + "\n              "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { data: _vm.form.headerList, border: "" },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { label: "key:", prop: "key" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-input", {
                                      staticClass: "new-form-input",
                                      attrs: {
                                        autocomplete: "off",
                                        placeholder: "key",
                                      },
                                      model: {
                                        value: scope.row.key,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "key", $$v)
                                        },
                                        expression: "scope.row.key",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "value:", prop: "value" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-input", {
                                      staticClass: "new-form-input",
                                      attrs: {
                                        autocomplete: "off",
                                        placeholder: "key",
                                      },
                                      model: {
                                        value: scope.row.value,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "value", $$v)
                                        },
                                        expression: "scope.row.value",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: this.$t("cip.cmn.btn.editBtn"),
                              prop: "address",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-button", {
                                      attrs: {
                                        circle: "",
                                        icon: "el-icon-delete",
                                        type: "danger",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.headerListDelFn(scope.row)
                                        },
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "el-collapse-item",
                    {
                      attrs: {
                        title: _vm.$t("cip.dc.zljkcj.field.RequestMethod"),
                        name: "3",
                      },
                    },
                    [
                      _c("template", { slot: "title" }, [
                        _c(
                          "i",
                          {
                            staticClass: "avue-group__title avue-group__header",
                            staticStyle: {
                              color: "#999999 !important",
                              "font-style": "normal",
                              padding: "0 10px",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-document",
                              staticStyle: {
                                "font-size": "20px",
                                padding: "0 10px",
                              },
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("cip.dc.zljkcj.field.RequestMethod")
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: "8" } }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-bottom": "20px",
                                  "padding-left": "30px",
                                  display: "flex",
                                  "align-items": "center",
                                },
                              },
                              [
                                _c("p", { staticStyle: { width: "50px" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("cip.dc.zljkcj.field.parameter")
                                    )
                                  ),
                                ]),
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "new-form-input",
                                    model: {
                                      value: _vm.form.timeLocation,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "timeLocation", $$v)
                                      },
                                      expression: "form.timeLocation",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { value: 1, label: "url" },
                                    }),
                                    _c("el-option", {
                                      attrs: {
                                        label: _vm.$t(
                                          "cip.dc.zljkcj.field.Requestor"
                                        ),
                                        value: 2,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm.form.timeLocation == 2
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          icon: "el-icon-circle-plus-outline",
                                        },
                                        on: {
                                          click: _vm.headerconfigListAddFn,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "cip.dc.zljkcj.field.AddRequestHeader"
                                            )
                                          ) + "\n                "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                          _vm.form.timeLocation == 2
                            ? _c(
                                "div",
                                _vm._l(
                                  _vm.form.configList,
                                  function (item, index) {
                                    return _c(
                                      "el-row",
                                      {
                                        key: index,
                                        staticStyle: {
                                          "margin-bottom": "10px",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 6 } },
                                          [
                                            _vm._v(
                                              "\n                  key:\n                  "
                                            ),
                                            _c("el-input", {
                                              staticClass: "new-form-input",
                                              staticStyle: { width: "150px" },
                                              attrs: {
                                                autocomplete: "off",
                                                placeholder: "key",
                                              },
                                              model: {
                                                value: item.key,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "key", $$v)
                                                },
                                                expression: "item.key",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 6 } },
                                          [
                                            _vm._v(
                                              "\n                  value:\n                  "
                                            ),
                                            _c("el-input", {
                                              staticClass: "new-form-input",
                                              staticStyle: { width: "150px" },
                                              attrs: {
                                                autocomplete: "off",
                                                placeholder: "value",
                                              },
                                              model: {
                                                value: item.value,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "value", $$v)
                                                },
                                                expression: "item.value",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 12 } },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "cip.dc.zljkcj.field.type"
                                                  )
                                                ) +
                                                "\n                  "
                                            ),
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: {
                                                  width: "200px!important",
                                                },
                                                model: {
                                                  value: item.type,
                                                  callback: function ($$v) {
                                                    _vm.$set(item, "type", $$v)
                                                  },
                                                  expression: "item.type",
                                                },
                                              },
                                              [
                                                _c("el-option", {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "cip.dc.zljkcj.field.constant"
                                                    ),
                                                    value: 1,
                                                  },
                                                }),
                                                _c("el-option", {
                                                  attrs: {
                                                    value: 2,
                                                    label: "json",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c("el-button", {
                                              attrs: {
                                                circle: "",
                                                icon: "el-icon-delete",
                                                type: "danger",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.headerconfigListDelFn(
                                                    index
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }